<template>
    <v-form ref="form">
        <v-card v-model="formHasErrors" lazy-validation>
            <v-card-title class="cms_modal__title">
                <span class="text-h5">{{ dialogEdit ? $t('action.edit_user') : $t('action.new_user') }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="profile__avatar" @click="handleClickAvatar">
                                <img
                                    width="100%"
                                    :src="imageState ? imageState.imgBase64 : cuItem.avatar || noImage"
                                    alt="avatar"
                                />
                                <div class="update-avatar">
                                    <v-icon class="fa-solid fa-image"></v-icon>
                                    <input
                                        type="file"
                                        ref="inputImage"
                                        accept="image/*"
                                        @change="handleUploadAvatar"
                                        hidden
                                    />
                                </div>
                            </div>
                            <v-text-field
                                ref="Full Name"
                                v-model="cuItem.full_name"
                                :label="$t('form.full_name')"
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                                required
                            ></v-text-field>

                            <v-text-field
                                ref="Email"
                                v-model="cuItem.email"
                                :label="$t('form.email')"
                                :disabled="dialogEdit"
                                :rules="[requiredRule, emailRule]"
                                required
                            ></v-text-field>

                            <v-text-field
                                ref="Password"
                                v-model="cuItem.password"
                                :label="$t('form.password')"
                                :disabled="dialogEdit"
                                :rules="[passwordRule('')]"
                                :counter="20"
                                maxlength="20"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                required
                                v-if="!dialogEdit"
                            ></v-text-field>

                            <v-select
                                ref="Gender"
                                v-model="cuItem.gender"
                                :label="$t('form.gender')"
                                :items="itemsGender"
                            ></v-select>

                            <!-- <v-select
                                ref="Role"
                                v-model="cuItem.role"
                                :label="$t('form.role')"
                                :items="itemsRole"
                                :disabled="dialogEdit"
                                :rules="[requiredRule]"
                                required
                            ></v-select> -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn type="button" color="blue darken-1" text @click="clickCancel"> {{ $t('cancel') }} </v-btn>
                <v-btn type="button" color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mixinRules } from '../../../mixins/rules';
import * as ImageAPI from '../../../api/imageAPI';

export default {
    mixins: [mixinRules],
    components: {},
    props: {
        cuItem: {
            type: Object,
        },
        formTitle: {
            type: String,
        },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
    },
    data() {
        return {
            itemsGender: [
                { text: this.$t('form.male'), value: 'MALE' },
                { text: this.$t('form.female'), value: 'FEMALE' },
                { text: this.$t('form.other'), value: 'OTHER' },
            ],
            itemsRole: [
                { text: this.$t('form.manager'), value: 'MANAGER' },
                { text: this.$t('cms.user'), value: 'USER' },
            ],
            selectTypeSection: null,
            errorMessages: '',
            formHasErrors: false,
            show1: false,
            imageState: null,
            noImage: require('../../../assets/images/no_image.jpg'),
        };
    },
    computed: {
        form() {
            return {
                avatar: this.cuItem.avatar,
                full_name: this.cuItem.full_name,
                email: this.cuItem.email,
                role: this.cuItem.role,
                gender: this.cuItem.gender,
                password: this.cuItem.password,
            };
        },
    },
    watch: {},
    methods: {
        validate() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });
        },

        resetForm() {
            this.errorMessages = [];
            this.formHasErrors = false;
            this.imageState = null;
            this.$refs.inputImage.value = null;

            Object.keys(this.form).forEach((f) => {
                this.$refs.form.resetValidation();
            });
        },

        async clickSave() {
            const isValid = this.$refs.form.validate();
            if (isValid) {
                if (this.imageState) {
                    let body = new FormData();
                    body.append('image', this.imageState.file);
                    const res = await ImageAPI.upload(body);
                    this.cuItem.avatar = res?.results?.object?.url;
                }
                this.save((isSuccess) => {
                    if (isSuccess) this.clickCancel();
                });
            }
        },

        handleClickAvatar() {
            this.$refs.inputImage && this.$refs.inputImage.click();
        },

        handleUploadAvatar(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.imageState = {
                    file,
                    imgBase64: reader.result,
                };
            });
            reader.readAsDataURL(file);
        },

        clickCancel() {
            this.closeCU();
            this.resetForm();
        },
    },
};
</script>

<style lang="scss">
.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }
    .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
